import React from "react";
import {
  SEO,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"left"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  destinationEmail={"sierra.green@healthyeatsatl.com"}
                  className={""}
                  style={{}}
                  businessId={1109}
                  businessType={"merchant"}
                  locationId={1172}
                  subheader={"Catering Form"}
                  showLabels={true}
                  emailSubject={"Catering Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Name",
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Email",
                    },
                    {
                      type: "input",
                      label: "phone_number",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Phone number",
                    },
                    {
                      type: "input",
                      label: "date",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Date",
                    },
                    {
                      type: "input",
                      label: "how_many_people",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "How many people?",
                    },
                    {
                      type: "textarea",
                      label: "options_of_food_requested",
                      required: true,
                      inputType: "textarea",
                      placeholder: "Options of food requested",
                      displayLabel: "Options of food requested",
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22460 }) {
      title
    }
  }
`;
